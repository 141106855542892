window.dataLayer = window.dataLayer || [];
window.addEventListener('heyflow-button-click', (event) => {
    if (event.detail.customEventName == "both-start") {
        window.dataLayer.push({
        	"event": "ga4_add_to_cart",
        	"screening": "hey-flow",
        	"path": window.location.pathname,
        	"currency": "USD",
        	"value": 199.99,
        	"items": [
        		{
        			"item_name": "Housing + Travel",
    			    "item_id": 3,
    			    "price": 199.99,
        			"quantity": 1 // Always 1.
        		}
        	],
        	"user_data": {
        		"user_id": "",
        		"em": ""
        	}
        });
    } else if (event.detail.customEventName == "travel-with-pet-start") {
        window.dataLayer.push({
        	"event": "ga4_add_to_cart",
        	"screening": "hey-flow",
        	"path": window.location.pathname,
        	"currency": "USD",
        	"value": 149.99,
        	"items": [
        		{
        			"item_name": "Travel",
        			"item_id": 2,
        			"price": 149.99,
        			"quantity": 1 // Always 1.
        		}
        	],
        	"user_data": {
        		"user_id": "",
        		"em": ""
        	}
        });
    } else if (event.detail.customEventName == "live-with-pet-start") {
        window.dataLayer.push({
        	"event": "ga4_add_to_cart",
        	"screening": "hey-flow",
        	"path": window.location.pathname,
        	"currency": "USD",
        	"value": 149.99,
        	"items": [
        		{
        			"item_name": "Housing",
    			    "item_id": 1,
    			    "price": 149.99,
        			"quantity": 1 // Always 1.
        		}
        	],
        	"user_data": {
        		"user_id": "",
        		"em": ""
        	}
        });
    }
})

window.addEventListener('heyflow-init', (event) => {
    window.dataLayer.push({
    	"event": "ga4_screening_started",
    	"screening": "hey-flow",
        "path": window.location.pathname,
    	"user_data": {
    		"user_id": "",
    		"em": ""
    	}
    });
    window.dataLayer.push({
    	"event": "ga4_view_item_list",
    	"screening": "hey-flow",
        "path": window.location.pathname,
    
    	// items array to list all the products in the page
    	"items": [
    		{
    			"item_name": "Housing",
    			"item_id": 1,
    			"price": 149.99,
    			"item_list_id": "ESA Screening"
    		},
    		{
    			"item_name": "Travel",
    			"item_id": 2,
    			"price": 149.99,
    			"item_list_id": "ESA Screening"
    		},
    		{
    			"item_name": "Housing + Travel",
    			"item_id": 3,
    			"price": 199.99,
    			"item_list_id": "ESA Screening"
    		}
    		
    	],
    	"user_data": {
    		"user_id": "",
    		"em": ""
    	}
    });
});
